<template>
    <b-container fluid>
        <b-row>
            <b-col>
                <b-button-toolbar class="mb-3">
                    <b-button-group class="ml-auto">
                        <b-button to="add">
                            <b-icon icon="plus" />
                            Добавить
                        </b-button>
                        <b-dropdown :disabled="!rebalansActive" right text="Ребалансировка">
                            <b-dropdown-item 
                                v-for="(stage, i) in stages"
                                :key="'stage-' + i" 
                                :data-stage="stage.id"
                                @click="rebalans" 
                            >
                                {{ stage.name }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="rebalans">
                                Все фазы
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-button-group>
                </b-button-toolbar>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table
                    ref="table"
                    striped
                    hover
                    :items="itemsProvider"
                    :fields="fields"
                    :busy.sync="isBusy"
                    :per-page="pagination.perPage"
                    :current-page="pagination.currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                >
                    <template #cell(actions)="row">
                        <b-button-toolbar>
                            <b-button-group class="ml-auto">
                                <b-button :to="{path:`${row.item.id}`}">
                                    <b-icon icon="info" />
                                </b-button>
                                <b-button
                                    :to="{path:`${row.item.id}/edit`}"
                                    :disabled="row.item.deleted || ($auth.user().type !== UserTypeEnum.admin && row.item.type === UserTypeEnum.admin)"
                                >
                                    <b-icon icon="pencil" />
                                </b-button>
                                <poster
                                    tag="b-button"
                                    :item-id="row.item.id"
                                    method="admin.users.delete"
                                    :disabled="row.item.deleted || ($auth.user().type !== UserTypeEnum.admin && row.item.type === UserTypeEnum.admin)"
                                    success-message="Данные удалены"
                                    @complete="refreshTable"
                                >
                                    <b-icon icon="trash" />
                                </poster>
                                <poster
                                    v-if="row.item.active && !($auth.user().type !== UserTypeEnum.admin && row.item.type === UserTypeEnum.admin)"
                                    tag="b-button"
                                    :item-id="row.item.id"
                                    method="admin.users.deactivate"
                                    @complete="refreshTable"
                                >
                                    <b-icon icon="pause" />
                                </poster>
                                <poster
                                    v-if="!row.item.active && !($auth.user().type !== UserTypeEnum.admin && row.item.type === UserTypeEnum.admin)"
                                    tag="b-button"
                                    :item-id="row.item.id"
                                    method="admin.users.activate"
                                    no-alert-dismiss
                                    @complete="refreshTable"
                                >
                                    <b-icon icon="play" />
                                </poster>
                            </b-button-group>
                        </b-button-toolbar>
                    </template>
                    <template #cell(status)="row">
                        <span v-if="row.item.deleted">Удален</span>
                        <span v-if="!row.item.active">Деактивирован</span>
                    </template>
                    <template #cell(stage)="row">
                        {{ row.item.stage ? row.item.stage.name : '' }}
                    </template>
                    <template #cell(sum)="row">
                        {{ sums[row.item.id] | numberFormat(0, '.', ' ') }}
                    </template>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle" />
                            <strong>Загрузка данных...</strong>
                        </div>
                    </template>
                    <template #cell(telegramUsername)="data">
                        <a :href="`https://${formateTelegram(data)}`" target="_blank"> {{ formateTelegram(data) }}</a>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <pagination
            v-model="pagination"
            :link-gen="paginationLinkGen"
        />
        <b-modal
            id="rebalansModal"
            :title="`Запустить Ребалансировку ${rebalansName}?`"
            @ok="startRebalans"
        >
            <p>
                Вы уверены что хотите запустить ребалансировку?
            </p>
            <template #modal-footer="{ ok, cancel }">
                <b-button-group 
                    class="w-100 flex-nowrap"
                    size="lg" 
                >
                    <b-button 
                        variant="primary" 
                        @click="ok"
                    >
                        Да
                    </b-button>
                    <b-button @click="cancel">
                        Нет
                    </b-button>
                </b-button-group>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import UserTypeEnum from "../../../enums/UserTypeEnum";
import Pagination from "./../../Pagination";

export default {
    components: {Pagination},
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "email",
                    label: "Email",
                },
                {
                    key: "name",
                    label: "ФИО",
                },
                {
                    key: "telegramUsername",
                    label: "Аккаунт коллектора",
                },
                {
                    key: "status",
                    label: "Статус",
                },
                {
                    key: "type",
                    label: "Тип",
                    formatter(value) {
                        // eslint-disable-next-line no-undef,no-prototype-builtins
                        return UserTypeEnum.hasOwnProperty(value) ? UserTypeEnum[value] : value;
                    },
                },
                {
                    key: "stage",
                    label: "Стадия",
                },
                {
                    key: "weight",
                    label: "Вес",
                },
                {
                    key: "sum",
                    label: "Размер портфеля",
                },
                {
                    key: "createdAt",
                    label: "Добавлен",
                    formatter(value) {
                        return window.formatDatetime(value)
                    },
                },
                {
                    key: "updatedAt",
                    label: "Изменен",
                    formatter(value) {
                        return window.formatDatetime(value)
                    },
                },
                {
                    key: "actions",
                    label: "",
                },
            ],
            isBusy: false,
            UserTypeEnum,
            sums: {},
            sortBy: this.$route.query.sortBy ?? null,
            sortDesc: this.$route.query.sortDesc === "true",
            pagination: {
                currentPage: parseInt(this.$route.query.currentPage ?? 1),
                perPage: parseInt(this.$route.query.perPage ?? 50),
                totalItems: 0,
            },
            rebalansActive: false,
            rebalansName: "",
            rebalansFaze: null,
            stages: [],
        }
    },
    watch: {
        "$route.query": {
            handler(query) {
                if (!query.currentPage || !query.perPage) {
                    this.$router.replace({query: this.getQueryParams({})}).catch(() => {});
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.onRebalansActive();
    },
    methods: {
        formateTelegram(data){
            return data?.item?.telegramUsername ? `t.me/${data.item.telegramUsername}` : ""
        },
        getQueryParams(options) {
            const params = Object.assign({
                currentPage: this.pagination.currentPage,
                perPage: this.pagination.perPage,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
            }, options);

            return Object.fromEntries(Object.entries(params).filter(([, v]) => v != null && !!v));
        },
        itemsProvider(ctx) {
            this.isBusy = true;

            const query = this.getQueryParams(ctx);

            this.$router.push({query}).catch(() => {});
            this.jsonRpc("admin.stages.fullList").then((res) => {
                this.stages = res;
            });

            return this
                .jsonRpc("admin.users.list", query)
                .then(async (result) => {
                    this.pagination.totalItems = result.users.totalItems;
                    this.sums = result.sums;
                    
                    return result.users.items;
                })
                .finally(async () => {
                    this.isBusy = false;
                });
        },
        paginationLinkGen(page) {
            return "?" + (new URLSearchParams(this.getQueryParams({currentPage: page}))).toString();
        },
        refreshTable() {
            this.$refs.table.refresh();
        },
        onRebalansActive() {
            this
                .jsonRpc("admin.getReBalanceAvailability")
                .then(async (res) => {
                    this.rebalansActive = res;
                });
        },
        rebalans(e) {
            this.$bvModal.show("rebalansModal");
            this.rebalansName = e.target.textContent;
            this.rebalansFaze = e.target.dataset.stage;
        },
        startRebalans() {
            this
                .jsonRpc("admin.makeReBalance", this.rebalansFaze ? { stageId: +this.rebalansFaze } : undefined);
        },
    },
}
</script>
