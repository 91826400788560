<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="../list">
                    <b-icon icon="list" />
                    Список
                </b-button>
                <b-button to="../add">
                    <b-icon icon="plus" />
                    Добавить
                </b-button>
                <b-button
                    v-if="$auth.user().type === UserTypeEnum.admin || (item && item.type !== UserTypeEnum.admin)"
                    to="edit"
                >
                    <b-icon icon="pencil" />
                    Редактировать
                </b-button>
                <poster
                    v-if="$auth.user().type === UserTypeEnum.admin || (item && item.type !== UserTypeEnum.admin)"
                    tag="b-button"
                    :item-id="$route.params.id"
                    method="admin.users.delete"
                    :disabled="!item || !!item.deletedAt"
                    success-message="Данные удалены"
                    @complete="onDeleted"
                >
                    <b-icon icon="trash" />
                    Удалить
                </poster>
                <poster
                    v-if="item && item.active && ($auth.user().type === UserTypeEnum.admin || (item && item.type !== UserTypeEnum.admin))"
                    tag="b-button"
                    :item-id="item.id"
                    method="admin.users.deactivate"
                    @complete="refreshData"
                >
                    <b-icon icon="pause" />
                    Деактивировать
                </poster>
                <poster
                    v-if="item && !item.active && ($auth.user().type === UserTypeEnum.admin || (item && item.type !== UserTypeEnum.admin))"
                    tag="b-button"
                    :item-id="item.id"
                    method="admin.users.activate"
                    @complete="refreshData"
                >
                    <b-icon icon="play" />
                    Активировать
                </poster>
                <b-button :disabled="!item || !!item.deletedAt" @click="rebalans">
                    <b-icon icon="arrow-repeat" />
                    Ребалансировка
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!item">
            <b-table
                :stacked="true"
                :items="rows"
                :fields="fields"
            >
                <template #cell(stage)="row">
                    {{ row.item.stage ? row.item.stage.name : '' }}
                </template>
                <template #cell(status)="row">
                    <span v-if="row.item.deleted">Удален</span>
                    <span v-if="!row.item.active">Деактивирован</span>
                </template>
            </b-table>
            <b-table
                v-if="rebalInfo"
                :items="rebalInfo"
                :fields="fieldsRebal"
            />
        </b-overlay>
    </div>
</template>

<script>
import UserTypeEnum from "../../../enums/UserTypeEnum";

export default {
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "email",
                    label: "Email",
                },
                {
                    key: "name",
                    label: "ФИО",
                },
                {
                    key: "telegramUsername",
                    label: "Аккаунт коллектора",
                },
                {
                    key: "status",
                    label: "Статус",
                },
                {
                    key: "stage",
                    label: "Стадия",
                },
                {
                    key: "weight",
                    label: "Вес",
                },
                {
                    key: "type",
                    label: "Тип",
                    formatter(value) {
                        // eslint-disable-next-line no-undef,no-prototype-builtins
                        return UserTypeEnum.hasOwnProperty(value) ? UserTypeEnum[value] : value;
                    },
                },
                {
                    key: "createdAt",
                    label: "Добавлен",
                    formatter(value) {
                        return window.formatDatetime(value)
                    },
                },
                {
                    key: "updatedAt",
                    label: "Изменен",
                    formatter(value) {
                        return window.formatDatetime(value)
                    },
                },
            ],
            item: null,
            UserTypeEnum,
            fieldsRebal: [
                {
                    key: "responsibleUserName",
                    label: "Коллектор",
                },
                {
                    key: "id",
                    label: "ID займа",
                },
                {
                    key: "sum",
                    label: "Сумма",
                },
                {
                    key: "planEndAt",
                    label: "Срок",
                    formatter(value) {
                        return window.formatDatetime(value)
                    },
                },
                {
                    key: "delayAmountInDays",
                    label: "Дней просрочки",
                },
                {
                    key: "segment",
                    label: "Сегмент (L1, L2, L3+)",
                },
            ],
            rebalInfo: null,
        }
    },
    computed: {
        rows() {
            if (!this.item) {
                return [];
            }

            return [this.item];
        }
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        refreshData() {
            const id = parseInt(this.$route.params.id);

            this.jsonRpc("admin.users.get", {id}).then(async (response) => {
                this.item = response;
            })
        },
        onDeleted() {
            this.$router.push("../list")
        },
        rebalans() {
            const id = parseInt(this.$route.params.id);
            this
                .jsonRpc("admin.loanBalancer.run", { id })
                .then(res => {
                    this.rebalInfo = res;
                });
        },
    }
}
</script>
